import { useFeatureToggleV2 } from '@/hooks'
import { $pricePerPointConfigurationStore } from '@/stores'
import * as FeatureToggle from '@/utils/featureToggle'

export const useIsToggleRedeemableItemsEnabled = (): boolean => {
	const { pricePerPointEnabled } = $pricePerPointConfigurationStore.getState()
	const isPricePerPointVendorSettingsEnabled = !!pricePerPointEnabled
	const isRedeemableItemsToggleEnabled = !!useFeatureToggleV2(FeatureToggle.REDEEMABLE_ITEMS_MODULE)

	return isRedeemableItemsToggleEnabled && isPricePerPointVendorSettingsEnabled
}

export const useIsTogglePricePerPointSettingsEnabled = (): boolean => {
	const isPerPricePointsSettingsEnabled = useFeatureToggleV2(FeatureToggle.PRICE_PER_POINT_SETTINGS)
	const isToggleRedeemableItemsEnabled = useIsToggleRedeemableItemsEnabled()
	return isToggleRedeemableItemsEnabled && isPerPricePointsSettingsEnabled!
}
