import InputChallengeCampaignId from '@/components/form/InputChallengeCampaignId'
import TabsChallenge from '@/components/tables/Tabs'
import { ChallengeExecutionMethod } from '@/domains/enums'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import useSupportedLanguages from '@/hooks/useSupportedLanguages/useSupportedLanguages'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { ChallengeSetupFormData, ChallengeStore } from '@/stores/challengeCreation'
import { isPurchaseOrVolume } from '@/utils/challenge'
import { Alert } from '@hexa-ui/components'
import { Typography } from '@material-ui/core'
import { useStoreMap } from 'effector-react'
import React, { useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ImageFile } from '@/domains/Challenge'
import { useFeatureToggleV2 } from '@/hooks'
import * as FeatureToggle from '@/utils/featureToggle'
import ChallengeFormWrapper from '../ChallengeFormWrapper/ChallengeFormWrapper'
import FormActions from '../FormActions/FormActions'
import UnfilledOptionalFormModal from '../UnfilledOptionalFormModal'
import ChallengeFormSetupExecutionMethod from './ChallengeFormSetupExecutionMethod'
import ChallengeFormSetupId from './ChallengeFormSetupId'
import ChallengeFormSetupInputs from './ChallengeFormSetupInputs/ChallengeFormSetupInputs'
import { Container, ContentInput, ContentInputChild, ControllerDiv, childDivInput } from './styles'
import ChallengeVisionIdInput from './ChallengeVisionIdInput/ChallengeVisionIdInput'

type Props = {
	onFormSubmit?: (formData: ChallengeSetupFormData) => void
	disableIdField?: boolean
	isEditing?: boolean
	optionalLanguagesUsed?: string[]
}

const ChallengeFormSetupView: React.FC<Props> = ({
	onFormSubmit,
	disableIdField = false,
	isEditing,
	optionalLanguagesUsed,
}: Props) => {
	const { t } = useTranslation()
	const [showAlertMessage, setShowAlertMessage] = useState<boolean>(true)
	const [showUnfilledOptionalModal, setShowUnfilledOptionalModal] = useState<boolean>(false)
	const { supportedLanguages } = useSupportedLanguages() ?? {}
	const isVisionId = useFeatureToggleV2(FeatureToggle.DEV_CHALLENGE_VISIONID)

	const isValidatingChallengeId = useStoreMap({
		store: ChallengeStore,
		keys: [],
		fn: (state) => state.validations.isValidatingChallengeId,
	})

	const {
		handleSubmit,
		watch,
		setValue,
		clearErrors,
		formState: { isValid },
	} = useFormContext()

	const executionMethod = watch('executionMethod')
	const coverImage = watch('image')
	const goodPhoto = watch('goodPhoto')
	const badPhoto = watch('badPhoto')

	const showIdField = !isPurchaseOrVolume({ executionMethod })
	const useGetInfo = useGetUserInfo()

	const isTakePhotoExecutionMethod = useMemo(
		() => ChallengeExecutionMethod.TakePhoto === executionMethod,
		[executionMethod],
	)

	const isEnable = useMemo(() => {
		const disableSubmitButton = !isValid || isValidatingChallengeId || !coverImage

		const disableByPhoto = disableSubmitButton || !goodPhoto || !badPhoto
		return executionMethod === ChallengeExecutionMethod.TakePhoto ? disableByPhoto : disableSubmitButton
	}, [badPhoto, coverImage, executionMethod, goodPhoto, isValid, isValidatingChallengeId])

	const handleChangeFile = (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
		const { files } = event.currentTarget
		if (files && files.length > 0) {
			const [file] = files
			setValue(
				field,
				{
					url: file.name,
					file,
				} as ImageFile,
				{
					shouldDirty: true,
					shouldValidate: true,
				},
			)
			if (!isEditing) {
				const [{ name, type }] = files
				AnalyticsService.events.fileUploaded({
					file_type: type,
					file_name: name,
					form_name: 'Challenge Creation - 1st Step',
					screen_name: 'Challenge Creation - 1st Step',
					...useGetInfo,
				})
			}
		}
	}

	const handleRemoveImage = (field: string, files: File) => {
		setValue(field, null)
		clearErrors(field)
		if (!isEditing) {
			const { name, type } = files
			AnalyticsService.events.fileRemoved({
				file_type: type,
				file_name: name,
				form_name: 'Challenge Creation - 1st Step',
				screen_name: 'Challenge Creation - 1st Step',
				...useGetInfo,
			})
		}
	}

	const handleProceedCreation = () => {
		if (!optionalLanguagesUsed?.length && supportedLanguages?.length && supportedLanguages?.length > 1)
			setShowUnfilledOptionalModal(!showUnfilledOptionalModal)
		else if (isValid && onFormSubmit) handleSubmit(onFormSubmit)()
	}

	return (
		<>
			<ChallengeFormWrapper isEditing={isEditing}>
				<UnfilledOptionalFormModal
					isOpen={showUnfilledOptionalModal}
					yesNoActions
					buttonActions={{
						proceed() {
							if (isValid && onFormSubmit) handleSubmit(onFormSubmit)()
						},
						cancel() {
							setShowUnfilledOptionalModal(!showUnfilledOptionalModal)
						},
					}}
				/>
				<Container>
					{!isEditing && <ChallengeFormSetupExecutionMethod />}
					{executionMethod && (
						<ControllerDiv>
							<ContentInput className={childDivInput()}>
								{showIdField && (
									<ContentInputChild id="firstInput">
										{isVisionId ? (
											<ChallengeVisionIdInput disableIdField={disableIdField} />
										) : (
											<ChallengeFormSetupId disableIdField={disableIdField} isNewDSM />
										)}
									</ContentInputChild>
								)}
								<ContentInputChild id="secondInput">
									<InputChallengeCampaignId isNewDSM />
								</ContentInputChild>
							</ContentInput>
						</ControllerDiv>
					)}

					{supportedLanguages?.length && supportedLanguages?.length > 1 ? (
						<>
							{showAlertMessage && supportedLanguages && (
								<Alert
									data-testid="multi-language-alert-message"
									style={{ width: '100%', marginTop: '24px' }}
									message={t('challenge:MULTI_LANGUAGE.MESSAGE_INFO')}
									showCloseButton
									onClose={() => setShowAlertMessage(!showAlertMessage)}
								/>
							)}
							<Typography variant="h5" style={{ margin: '24px 0px', fontWeight: '600', lineHeight: '24px' }}>
								{t('challenge:MULTI_LANGUAGE.SET_CHALLENGE_INFO')}
							</Typography>
							<TabsChallenge
								handleChangeFile={handleChangeFile}
								handleRemoveImage={handleRemoveImage}
								supportedLanguages={supportedLanguages}
								isTakePhotoExecutionMethod={isTakePhotoExecutionMethod}
							/>
						</>
					) : (
						<ChallengeFormSetupInputs
							handleChangeFile={handleChangeFile}
							handleRemoveImage={handleRemoveImage}
							isTakePhotoExecutionMethod={isTakePhotoExecutionMethod}
						/>
					)}
				</Container>
			</ChallengeFormWrapper>
			{!isEditing && onFormSubmit && (
				<FormActions
					primaryButton={{
						type: 'continue',
						clickHandler: handleProceedCreation,
						disabled: isEnable,
					}}
				/>
			)}
		</>
	)
}

export default ChallengeFormSetupView
