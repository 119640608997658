import React, { useEffect, useRef, useState } from 'react'
import { Dialog, Heading, IconButton, Paragraph, Table } from '@hexa-ui/components'
import { EyeOn } from '@hexa-ui/icons'
import { GridContainer, GridItem } from '@/components/templates/GridTemplate'
import { useTranslation } from 'react-i18next'
import TransactionStatusBadge from '@/components/core/TransactionStatusBadge'
import getTransactionUseCase from '@/usecase/transaction/getTransactionUseCase'
import { TransactionResponseV2 } from '@/domains/transaction/TransactionResponse'
import Dropdown from '@/components/core/Dropdown'
import { TransparencyStatus } from '@/domains/enums'
import {
	transactionDetailDialogColumns,
	transactionDetailDialogFields,
	transactionDetailDialogReasons,
	Transaction,
} from '@/utils/transactionsVariablesUtil'
import * as CancelTransactionUseCase from '@/usecase/transactions/CancelTransactionUseCase'
import { gridContainerStyles, useStyles } from './TransactionDetailDialogStyle'
import ConfirmActionDialog, { IConfirmActionDialogButton } from './ConfirmActionDialog/ConfirmActionDialog'

interface TransactionDetailProps {
	transactionId: string
}

const TransactionDetailDialog: React.FC<TransactionDetailProps> = ({ transactionId }) => {
	const classes = useStyles()
	const { t } = useTranslation()

	const [transaction, setTransaction] = useState<TransactionResponseV2 | null>(null)
	const [isOpenDropdownMenu, setIsOpenDropdownMenu] = useState<boolean>(false)
	const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false)
	const [executionStatuses, setExecutionStatuses] = useState<{ [key: string]: string }>({})
	const [transparencyIdToCancel, setTransparencyIdToCancel] = useState<string | null>(null)

	const reasons = useRef<Transaction[]>([])
	const fields = useRef<Transaction[]>([])
	const confirmActionDialogButtons = useRef<IConfirmActionDialogButton[]>()

	const columns = transactionDetailDialogColumns.map((col) => ({
		...col,
		Header: t(col.Header),
	}))

	useEffect(() => {
		if (transaction) {
			fields.current = transactionDetailDialogFields(transaction).map((field) => {
				const translatedValue = typeof field.value === 'string' ? t(field.value) : null
				return { title: t(field.title), value: translatedValue }
			})

			reasons.current = transactionDetailDialogReasons(transaction).map((reason) => ({
				...reason,
				title: t(reason.title),
			}))
		}
	}, [transaction, t])

	useEffect(() => {
		const fetchTransaction = async () => {
			try {
				const response = await getTransactionUseCase({ transactionId })
				setTransaction(response)

				const initialStatuses = response?.executions.reduce(
					(acc, execution) => {
						acc[execution.transparencyId] = execution.status
						return acc
					},
					{} as { [key: string]: string },
				)

				if (initialStatuses) setExecutionStatuses(initialStatuses)
			} catch (error) {
				console.error('Failed to load transaction', error)
			}
		}

		fetchTransaction()
	}, [transactionId])

	const handleCancelRequestClick = (event: React.MouseEvent, transparencyId: string) => {
		event.preventDefault()
		setIsConfirmDialogOpen(true)
		setTransparencyIdToCancel(transparencyId)
	}

	const cancelTransaction = async (vendorId: string, transparencyId: string): Promise<void> => {
		const cancelTransactionData = await CancelTransactionUseCase.cancel(vendorId, transparencyId)

		if (cancelTransactionData === 200 && transparencyIdToCancel) {
			setExecutionStatuses((prevStatuses) => ({
				...prevStatuses,
				[transparencyId]: 'CANCELED',
			}))

			setTransparencyIdToCancel(null)
		}
	}

	const downloadCSV = (): void => {
		console.log('Download CSV button clicked') // TO DO: integrate with useCase
	}

	const statusWithActions = transaction?.executions?.map((execution) => {
		if (!execution.transparencyId) return null

		const dropdownButtons = [
			{ key: `download_${execution.transparencyId}`, buttonText: 'common:BUTTON.DOWNLOAD' },
			{
				key: `cancel-request_${execution.transparencyId}`,
				buttonText: 'common:BUTTON.CANCEL_REQUEST',
				onClickAction: (event: React.MouseEvent) => handleCancelRequestClick(event, execution.transparencyId),
			},
		]

		confirmActionDialogButtons.current = [
			{
				buttonText: 'transaction:TRANSACTION_DETAIL.CANCEL_TRANSACTION.BUTTON.CANCEL',
				onClickAction: () => cancelTransaction(transaction.vendorId, execution.transparencyId),
				key: 'cancel',
				variant: 'destructive',
			},
			{
				buttonText: 'transaction:TRANSACTION_DETAIL.CANCEL_TRANSACTION.BUTTON.BACK',
				onClickAction: () => downloadCSV(),
				key: 'back',
				variant: 'secondary',
			},
		]

		return {
			...execution,
			status: (
				<div className={classes.tableStatus}>
					<TransactionStatusBadge
						type={(executionStatuses[execution.transparencyId] as keyof typeof TransparencyStatus) || execution.status}
					/>
				</div>
			),
			actions: (
				<Dropdown
					isOpenDropdownMenu={isOpenDropdownMenu}
					setIsOpenDropdownMenu={setIsOpenDropdownMenu}
					buttons={dropdownButtons}
					iconButtonProps={{
						status: executionStatuses[execution.transparencyId],
						onClick: () => setIsOpenDropdownMenu((prev) => !prev),
					}}
				/>
			),
		}
	})

	return (
		<div className={classes.dialogRoot}>
			<Dialog.Root
				title={<Heading>{t('transaction:TRANSACTION_DETAIL.TITLE')}</Heading>}
				trigger={
					<IconButton data-testid="icon-button-test-id" icon={() => <EyeOn fr={undefined} />} variant="tertiary" />
				}
				onOpenChange={(open) => {
					if (!open) {
						setIsOpenDropdownMenu(false)
					}
				}}
			>
				<GridContainer style={gridContainerStyles}>
					{fields.current
						.filter((field) => field.value)
						.map((field) => {
							const descriptionFields = [
								t('transaction:TRANSACTION_DETAIL.REASON'),
								t('transaction:TRANSACTION_DETAIL.DESCRIPTION'),
							]
							const titleLowerCase = field.title?.toLowerCase()
							const FULL_ROW = 12
							const HALF_ROW = 6

							const isDescriptionField = descriptionFields.some((keyword) =>
								titleLowerCase?.includes(keyword.toLowerCase()),
							)

							const gridColumnSize = isDescriptionField ? FULL_ROW : HALF_ROW

							return (
								<GridItem xs={gridColumnSize} key={field.title}>
									<Heading size="H5" className={classes.itemTitle}>
										{field.title}
									</Heading>
									<Paragraph>{field.value}</Paragraph>
								</GridItem>
							)
						})}
				</GridContainer>
				<div className={classes.tableContainer} data-testid="table-container">
					<Table pagination={false} columns={columns} data={statusWithActions ?? []} />
				</div>
				{reasons.current.map(
					(reason) =>
						reason.value && (
							<div key={reason.title} className={classes.containerItem}>
								<Heading size="H5" className={classes.rejectionReason}>
									{reason.title}
								</Heading>
								<Paragraph>{reason.value}</Paragraph>
							</div>
						),
				)}
				<ConfirmActionDialog
					open={isConfirmDialogOpen}
					onClose={() => setIsConfirmDialogOpen(false)}
					buttons={confirmActionDialogButtons.current}
				/>
			</Dialog.Root>
		</div>
	)
}

export default TransactionDetailDialog
