import { createStyles, makeStyles } from '@material-ui/core/styles'

const RedeemableItemsEmptyStateStyle = makeStyles(() =>
	createStyles({
		mainWrapper: {
			alignItems: 'flex-end',
			width: '100%',
			margin: '48px 0',
			padding: '24px',
		},
		emptyContainer: {
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			width: '100%',
			padding: '0px 24px 48px 24px',
		},
		pageLevelWrapper: {
			flexDirection: 'column',
			alignItems: 'center',
			color: 'var(--colors-interfaceLabelPrimary)',
			width: '100%',
			justifyContent: 'center',

			// Override
			'& > div': {
				width: '50%',
				'& > h2, & > p': {
					width: '100%',
				},
			},
		},
		cppCardContainer: {
			width: '100%',
			marginBottom: '24px',
		},
	}),
)

export default RedeemableItemsEmptyStateStyle
