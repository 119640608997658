import { useEffect, useState } from 'react'
import { useFeatureToggleServiceV2, useAuthenticationService } from 'admin-portal-shared-services'

import { PROJECT_NAME } from '@/utils/constants'
import useCurrentZone from '../zone/useCurrentZone'

export const useFeatureToggleV2 = (featureToggleName: string): boolean | undefined => {
	const [isToggle, setIsToggle] = useState<boolean | undefined>(undefined)
	const userId = useAuthenticationService().getUserId()
	const selectedZone = useCurrentZone()

	const featureToggleService = useFeatureToggleServiceV2(PROJECT_NAME)

	useEffect(() => {
		featureToggleService.onReady().then(() => {
			featureToggleService
				.isFeatureEnabledAudiences(featureToggleName, userId, { COUNTRY: selectedZone })
				.then((response) => setIsToggle(response))
				.catch(() => setIsToggle(false))
		})
	}, [featureToggleService, featureToggleName, userId, selectedZone])

	return isToggle
}

export default useFeatureToggleV2
