import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, EmptyState, Grid, LoadingBuzz } from '@hexa-ui/components'

import { ViewState } from '@/domains/redeemable/ViewState'
import { classNames } from 'classnames'
import { TypeToast, useToast } from 'admin-portal-shared-services'
import RedeemableItemsEmptyStateStyle from './RedeemableItemsEmptyStateStyle'
import { useRedeemableItemsEmptyStateFlow } from './useRedeemableItemsEmptyStateFlow'
import { AddRedeemableDialog } from '../../../AddRedeemableDialog/AddRedeemableDialog'
import { EditDefaultCppCard } from '../../../EditDefaultCppCard/EditDefaultCppCard'
import { EditDefaultCppDialog } from '../../../EditDefaultCPPDialog/EditDefaultCPPDialog'

type RedeemableItemsEmptyStateProps = {
	viewState: ViewState
}

export const RedeemableItemsEmptyState = ({ viewState }: RedeemableItemsEmptyStateProps) => {
	const { isCreateModalOpen, onCreateNewButtonClicked } = useRedeemableItemsEmptyStateFlow()
	const [isEditDefaultCppOpened, setIsEditDefaultCppOpened] = useState(false)
	const [isEditDefaultCppRequestPending, setIsEditDefaultCppRequestPending] = useState(false)

	const isLoading = viewState === ViewState.LOADING

	const { t } = useTranslation()
	const css = RedeemableItemsEmptyStateStyle()

	const renderLoadingState = () => (
		<Grid.Item className={css.emptyContainer}>
			<LoadingBuzz size="large" data-testid="redeemable-items-empty-state-loading" />
		</Grid.Item>
	)

	const toast = useToast()

	const renderEmptyState = () => (
		<Grid.Item className={css.emptyContainer} data-testid="redeemable-items-empty-state-content">
			<AddRedeemableDialog isOpened={isCreateModalOpen} />
			<EditDefaultCppDialog
				open={isEditDefaultCppOpened}
				onClose={() => setIsEditDefaultCppOpened(false)}
				onUpdate={() => {
					setIsEditDefaultCppRequestPending(true)
					setIsEditDefaultCppOpened(false)
					toast.notify({
						type: TypeToast.SUCCESS,
						message: t('redeemable:UPDATE_CPP.TOAST_SUCCESS'),
					})
				}}
			/>
			<div className={css.cppCardContainer}>
				<EditDefaultCppCard
					variant="emptyState"
					onEdit={() => setIsEditDefaultCppOpened(true)}
					isPending={isEditDefaultCppRequestPending}
				/>
			</div>
			<Grid.Item className={css.pageLevelWrapper}>
				<EmptyState.PageLevel
					ilustrationName="beesProductLevel"
					title={t('redeemable:EMPTY_STATE.TITLE')}
					description={t('redeemable:EMPTY_STATE.DESCRIPTION')}
					actions={[
						{
							name: t('redeemable:BUTTON.CREATE_NEW'),
							variant: 'primary',
							action: onCreateNewButtonClicked,
						},
					]}
				/>
			</Grid.Item>
		</Grid.Item>
	)

	return (
		<Card border="large" elevated="small" className={css.mainWrapper}>
			{isLoading ? renderLoadingState() : renderEmptyState()}
		</Card>
	)
}
