import { useAuthorization, useFeatureToggleV2 as verifyFeatureToggle } from '@/hooks'
import { sortRoutes } from '@/utils/routesUtil'
import { useIsToggleRedeemableItemsEnabled } from '@/utils/featureToggle/featureToggleHelper'
import { RouteInstance } from '../../domains/route/RouteInstance'
import { routes } from '../../routes/routes'
import useCurrentZone from '../zone/useCurrentZone'
import { removeRoutesIfNecessary } from './authorizedRoutesHelper'

const useAuthorizedRoutes = (): Array<RouteInstance> => {
	const { isFeatureAuthorized } = useAuthorization()
	const selectedZone = useCurrentZone()
	const isToggleRedeemableItemsEnabled = useIsToggleRedeemableItemsEnabled()

	if (!selectedZone) {
		return []
	}
	const authorizedRoutes = sortRoutes(Object.values(routes)).filter((route) => {
		const isEnabled = verifyFeatureToggle(route.authorizationFeature.featureName)

		return isFeatureAuthorized(isEnabled!, {
			feature: route.authorizationFeature.feature,
			permissionLevel: route.authorizationFeature.permissionLevel,
		})
	})

	return removeRoutesIfNecessary({ list: authorizedRoutes, isToggleRedeemableItemsEnabled })
}

export default useAuthorizedRoutes
