import { RewardsOfferTransaction, TransactionResponse } from '@/domains'
import { addPoints, rewardsOffers } from '@/services/transaction/TransactionService'

async function execute({
	accountId,
	rewardsOfferTransaction,
	isIdentifyDimensionEnabled,
}: {
	accountId: string
	rewardsOfferTransaction: RewardsOfferTransaction
	isIdentifyDimensionEnabled: boolean
}): Promise<TransactionResponse> {
	return isIdentifyDimensionEnabled
		? addPoints(rewardsOfferTransaction)
		: rewardsOffers(accountId, rewardsOfferTransaction)
}

export { execute }
