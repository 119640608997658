import { useEffect, useState } from 'react'
import { useFeatureToggleServiceV2, useAuthenticationService } from 'admin-portal-shared-services'

import { PROJECT_NAME } from '@/utils/constants'
import FeatureToggleServiceV2 from 'admin-portal-shared-services/dist/services/featureToggle/featureToggleOptimizelyServiceV2/FeatureToggleServiceV2'
import useCurrentZone from '../zone/useCurrentZone'

export const useFeatureToggleV2 = (featureToggleName: string): boolean | undefined => {
	const [isToggle, setIsToggle] = useState<boolean | undefined>(undefined)
	const userId = useAuthenticationService().getUserId()
	const selectedZone = useCurrentZone()

	const featureToggleService = useFeatureToggleServiceV2(PROJECT_NAME)

	useEffect(() => {
		featureToggleService
			.onReady()
			.then(() => {
				featureToggleService
					.isFeatureEnabledAudiences(featureToggleName, userId, { COUNTRY: selectedZone })
					.then((response) => {
						setIsToggle(response)
					})
			})
			.catch(() => {
				setIsToggle(false)
			})
	}, [featureToggleService, featureToggleName, userId, selectedZone])

	return isToggle
}

export const useGetFeatureToggleInstance = () => {
	const [instanceStatus, setInstanceStatus] = useState<{
		isReady: boolean
		featureToggleService?: FeatureToggleServiceV2
	}>({
		isReady: false,
	})

	const featureToggleService = useFeatureToggleServiceV2(PROJECT_NAME)
	console.log({ featureToggleService })
	useEffect(() => {
		featureToggleService
			.onReady()
			.then(() => {
				setInstanceStatus({
					isReady: true,
					featureToggleService,
				})
			})
			.catch((err) => {
				setInstanceStatus({
					isReady: false,
					featureToggleService: undefined,
				})
			})
	}, [featureToggleService])

	return instanceStatus
}

export default useFeatureToggleV2
