import { useCallback } from 'react'
import { ProgramProjection } from '@/domains/enums'
import { GlobalStore, RulesEvents } from '@/stores'
import * as ProgramRulesToRules from '@/converters/program/ProgramRulesToRules'
import * as FeatureToggle from '@/utils/featureToggle'
import { useStore } from 'effector-react'
import { LoadSettingsRulesUseCase, LoadProgramUseCase } from '@/usecase'
import useCurrentSettings from '@/hooks/settings/useCurrentSettings'
import { useGetMultiplesFeatureToggles } from '@/hooks/toggle/useGetMultiplesFeatureToggles'

export interface LoadRulesResult {
	loadRules: () => Promise<void>
	togglesReady: boolean
}

export const useLoadRules = (): LoadRulesResult => {
	const { selectedTierKey, selectedProgram } = useStore(GlobalStore)
	const settings = useCurrentSettings()

	const togglesList = [
		FeatureToggle.DEV_MULTIVENDOR_EARNING_RULES_BY_VENDOR,
		FeatureToggle.SETTINGS_CONFIG_BY_ZONE,
		FeatureToggle.DEV_MULTI_VENDOR_EARNING_RULES,
	]

	const { togglesReady, featuresListByToggleName } = useGetMultiplesFeatureToggles(togglesList)

	const isEarningRulesByVendorEnabled = featuresListByToggleName[FeatureToggle.DEV_MULTIVENDOR_EARNING_RULES_BY_VENDOR]
	const isSettingsConfigByZoneEnabled = featuresListByToggleName[FeatureToggle.SETTINGS_CONFIG_BY_ZONE]
	const isMultiVendorEarningRulesEnabled = featuresListByToggleName[FeatureToggle.DEV_MULTI_VENDOR_EARNING_RULES]

	const loadRulesByVendor = useCallback(async () => {
		if (!selectedProgram || !selectedTierKey) {
			throw new Error('Wrong params!')
		}
		const rules = await LoadSettingsRulesUseCase.execute(selectedTierKey, {
			vendorId: !isSettingsConfigByZoneEnabled ? settings.vendorId : undefined,
		})
		RulesEvents.loadRules({
			programId: selectedProgram.id,
			rules: ProgramRulesToRules.convertAndSortRules(rules, isMultiVendorEarningRulesEnabled),
		})
	}, [isMultiVendorEarningRulesEnabled, selectedTierKey, selectedProgram, settings, isSettingsConfigByZoneEnabled])

	const loadRulesByProgram = useCallback(async () => {
		const program = await LoadProgramUseCase.execute(ProgramProjection.Rules)
		if (program) {
			RulesEvents.loadRules({
				programId: program.id,
				rules: ProgramRulesToRules.convertAndSortRules(program.rules, isMultiVendorEarningRulesEnabled),
			})
		}
	}, [isMultiVendorEarningRulesEnabled])

	const loadRules = useCallback(async (): Promise<void> => {
		try {
			if (isEarningRulesByVendorEnabled) {
				await loadRulesByVendor()
			} else {
				await loadRulesByProgram()
			}
		} catch {
			RulesEvents.loadRules({
				programId: '',
				rules: [],
			})
		}
	}, [loadRulesByProgram, loadRulesByVendor, isEarningRulesByVendorEnabled])

	return {
		loadRules,
		togglesReady,
	}
}
