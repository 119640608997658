import React from 'react'
import { Route, Switch, Redirect, useHistory } from 'react-router-dom'
import { BASE_ROUTE } from 'utils/constants'
import { useFeatureToggleV2 } from '@/hooks'
// eslint-disable-next-line import/no-cycle
import { useSetAppHeaderOptionalButtons } from '@/hooks/useSetAppHeaderOptionalButtons/useSetAppHeaderOptionalButtons'
import { Plus } from '@admin-portal-shared-components/icons'
import { useTranslation } from 'react-i18next'
import * as FeatureToggle from '@/utils/featureToggle'
import OldTransactionLog from './components/TransactionLog/TransactionLog'
import NewTransactionLog from './components/TransactionLogV2/TransactionLog'

const TRANSACTIONS_PATH = `${BASE_ROUTE}/transactions`

const TransactionsRoute: React.FC = () => {
	const isNewTransactionLogsToggleEnabled = useFeatureToggleV2(FeatureToggle.NEW_TRANSACTION_LOGS)
	const { t } = useTranslation()
	const history = useHistory()

	const TransactionLog = isNewTransactionLogsToggleEnabled ? NewTransactionLog : OldTransactionLog

	useSetAppHeaderOptionalButtons(
		isNewTransactionLogsToggleEnabled
			? {
					primaryButton: {
						label: t('transaction:LOG:NEW_TRANSACTION_BTN'),
						onClick: () => history.push('/membership/transaction'),
						icon: () => Plus,
					},
				}
			: {},
	)

	return (
		<Switch>
			<Route exact path={TRANSACTIONS_PATH}>
				<TransactionLog />
			</Route>
			<Redirect path={`${TRANSACTIONS_PATH}/*`} to={TRANSACTIONS_PATH} />
		</Switch>
	)
}

export default TransactionsRoute
