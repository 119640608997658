import * as TransactionsService from '@/services/transactions/TransactionsService'

export async function cancel(vendorId: string, transparencyId: string): Promise<number | null | unknown> {
	try {
		const canceledTransaction = await TransactionsService.cancelTransaction(vendorId, transparencyId)
		return canceledTransaction
	} catch (error: any) {
		console.error(`Failed to cancel transaction. ${error.message}`)
		return null
	}
}
