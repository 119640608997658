import { AppHeaderConfigPayload, useAppHeader } from 'admin-portal-shared-services'
import { useEffect } from 'react'
import { useActiveRouteSettings } from '../activeRoute/useActiveRouteSettings'
import { SetAppHeaderOptionalButtonsProps } from './useSetAppHeaderOptionalButtons.types'
// eslint-disable-next-line import/no-cycle
import useAuthorizedRoutes from '../authorizedRoute/useAuthorizedRoutes'

function useSetAppHeaderOptionalButtons({
	primaryButton,
	secondaryButton,
	isNewListEnabled,
}: SetAppHeaderOptionalButtonsProps): void {
	const authorizedRoutes = useAuthorizedRoutes()

	const { pageTitle, breadcrumbConfig } = useActiveRouteSettings(authorizedRoutes)

	const [, setAppHeaderConfig] = useAppHeader()

	useEffect(() => {
		if (isNewListEnabled) {
			const newAppHeaderConfig: AppHeaderConfigPayload = {
				pageTitle,
				vendorSelect: false,
				countrySelect: false,
				breadcrumbConfig,
				optionalButtons: [primaryButton, secondaryButton].filter(
					(button) => button !== undefined,
				) as unknown as AppHeaderConfigPayload['optionalButtons'],
			}

			setAppHeaderConfig(newAppHeaderConfig)

			const optionalButtonsCleanUp = (): void =>
				setAppHeaderConfig({
					...newAppHeaderConfig,
					optionalButtons: [],
				})

			return optionalButtonsCleanUp
		}
		return () => {}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
}

export { useSetAppHeaderOptionalButtons }
