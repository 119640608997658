import { RedeemableItem } from '@/domains/redeemable/RedeemableItem'
import { useFormatCurrency } from '@/hooks/useFormatNumber'
import { $pricePerPointConfigurationStore } from '@/stores/redeemableItems/PricePerPointConfigurationStore'
import { isCriticalPPP, pppValidRange } from '@/usecase/redeemableItems/CriticalPricePerPointUseCase'

import Typography from '@material-ui/core/Typography'
import { useStore } from 'effector-react'
import React from 'react'
import { styles } from './styles'
import { AlertsTooltipIcon } from '../AlertsTooltipIcon/AlertsTooltipIcon'

export const RedeemableItemPricePerPoint = ({ item }: { item: RedeemableItem }) => {
	const css = styles()
	const { pricePerPoint } = item
	const pppFormattedValue = useFormatCurrency(pricePerPoint, '')
	const { pricePerPoint: defaultPricePerPoint } = useStore($pricePerPointConfigurationStore)
	const defaultPPPFormatedValue = useFormatCurrency(defaultPricePerPoint)

	const { minimum, maximum } = pppValidRange(defaultPricePerPoint)
	const minimumPrice = useFormatCurrency(minimum)
	const maximumPrice = useFormatCurrency(maximum)

	const shouldShowCustomPricePerPointContainer = pricePerPoint === defaultPricePerPoint || pricePerPoint === null

	const shouldShowWarning = !pricePerPoint ? false : isCriticalPPP(pricePerPoint, defaultPricePerPoint)
	// TO DO: Implement real logic on BFF
	const shouldShowPending = false
	const shouldShowRejected = false

	return (
		<div>
			{shouldShowCustomPricePerPointContainer ? (
				<div className={css.customPricePerPointContainer}>
					<Typography
						data-testid="default-price-per-point-value"
						variant="inherit"
						color="primary"
						className={css.defaultPricePerPoint}
					>
						{defaultPPPFormatedValue}
					</Typography>

					{shouldShowPending && <AlertsTooltipIcon type="pending" CPP={defaultPPPFormatedValue} />}
					{shouldShowRejected && <AlertsTooltipIcon type="rejected" rejectionReason="rejectionReason" />}
				</div>
			) : (
				<div className={css.container}>
					<Typography
						data-testid="default-price-per-point-disabled-value"
						variant="inherit"
						color="primary"
						className={css.disabledDefaultPricePerPoint}
					>
						{defaultPPPFormatedValue}
					</Typography>
					<div className={css.customPricePerPointContainer}>
						<Typography
							data-testid="custom-price-per-point-value"
							variant="h6"
							color="primary"
							className={css.customPricePerPoint}
						>
							{pppFormattedValue}
						</Typography>

						{shouldShowWarning && (
							<AlertsTooltipIcon type="warning" minimumPrice={minimumPrice} maximumPrice={maximumPrice} />
						)}
					</div>
				</div>
			)}
		</div>
	)
}
