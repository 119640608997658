import Combo from '@/domains/combo/Combo'
import { loadById, loadMultivendorComboById } from '@/services/combo/ComboService'

export interface LoadCombosParams {
	comboIds?: Array<string>
	vendorComboIds?: Array<string>
}

export async function executeByVendorComboIds(
	params?: {
		vendorComboIds?: Array<string>
		isDevMultivendorCombosEnabled?: boolean
	} | null,
): Promise<Array<Combo>> {
	console.log('======> veio', params)
	if (params?.isDevMultivendorCombosEnabled) {
		return loadMultivendorComboById({
			vendorComboIds: params?.vendorComboIds,
			deleted: false,
			disabled: false,
		})
	}
	return loadById(params?.vendorComboIds)
}

export async function executeByComboIds({
	comboIds,
	isDevMultivendorCombosEnabled,
}: {
	comboIds?: Array<string>
	isDevMultivendorCombosEnabled: boolean
}): Promise<Array<Combo>> {
	if (isDevMultivendorCombosEnabled) {
		return loadMultivendorComboById({
			comboIds,
			deleted: false,
			disabled: false,
		})
	}
	return loadById(comboIds)
}
