import React, { useEffect, useState, useMemo } from 'react'
import PageLevelTemplate from '@/components/templates/PageLevelTemplate'
import { useAuthorization, useFeatureToggleV2 } from '@/hooks'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { TABLE_PAGINATION_CONFIG } from '@/utils/constants'
import { Button, SearchField, Table } from '@hexa-ui/components'
import { Plus } from '@hexa-ui/icons'
import { useTranslation } from 'react-i18next'
import { ScreenName } from '@/domains'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import { GridContainer, GridItem } from '@/components/templates/GridTemplate'
import * as FeatureToggle from '@/utils/featureToggle'
import { useNavigateToRules } from '../../../hooks/useNavigateToRules'
import useRuleDataTable from '../../../hooks/useRuleDataTable'
import { createEarningRuleButtonEnabled } from '../../utils/createEarningRuleButtonEnabled'
import RulesListStyles from './RulesListStyle'

type Props = {
	loading: boolean
}

const RulesListRedesignView: React.FC<Props> = ({ loading }) => {
	const userInfo = useGetUserInfo()
	const { t } = useTranslation()
	const { goToCreateRule } = useNavigateToRules()
	const classes = RulesListStyles()
	const { isFeatureAuthorized } = useAuthorization()
	const isToggledCreateEarningRulesEnabled = useFeatureToggleV2(FeatureToggle.CREATE_EARNING_RULES)

	const createEarningRuleEnabled = createEarningRuleButtonEnabled({
		isFeatureAuthorized,
		isToggledCreateEarningRulesEnabled: isToggledCreateEarningRulesEnabled!,
	})
	const { dataRules, setRuleFilter } = useRuleDataTable()
	const [pageData, setPageData] = useState({
		page: 1,
		pageSize: 10,
	})

	const pageDataEventParams = useMemo(
		() => ({
			items_total: `${dataRules.length}`,
			items_per_page: `${pageData.pageSize}`,
			page_number: `${pageData.page}`,
			table_name: ScreenName.EarningRules,
			screen_name: ScreenName.EarningRules,
		}),
		[dataRules.length, pageData.page, pageData.pageSize],
	)

	useEffect(() => {
		if (dataRules.length) {
			AnalyticsService.events.tableViewed({
				...pageDataEventParams,
				...userInfo,
			})
		}
	}, [dataRules.length, pageDataEventParams, userInfo])

	return (
		<GridContainer>
			<PageLevelTemplate />
			<div className={classes.mainTable}>
				<GridContainer style={{ padding: '16px', justifyContent: 'space-between' }}>
					<GridItem xs={6} style={{ maxWidth: '628px' }}>
						<SearchField.Root
							size="medium"
							onClear={() => setRuleFilter('')}
							placeholder={t('rules:EARNING_RULES_TABLE.SEARCH_BY_RULE_NAME')}
							onChange={(ev) => {
								setRuleFilter((ev.target as HTMLInputElement).value)
								AnalyticsService.events.tableSearched({
									search_query: (ev.target as HTMLInputElement).value,
									content_type: ScreenName.EarningRules,
									table_name: ScreenName.EarningRules,
									screen_name: ScreenName.EarningRules,
									...userInfo,
								})
							}}
						/>
					</GridItem>
					{createEarningRuleEnabled && (
						<GridItem>
							<Button
								icon={() => <Plus size="large" />}
								leading
								size="medium"
								variant="primary"
								className={classes.createRuleButton}
								onClick={() => {
									AnalyticsService.events.buttonClicked({
										button_name: 'New Earning Rule',
										button_label: 'Create new earning rule',
										screen_name: ScreenName.EarningRules,
										...userInfo,
									})
									goToCreateRule()
								}}
							>
								{t('rules:CREATE_EARNING_RULES.CREATE_NEW_EARNING_RULE')}
							</Button>
						</GridItem>
					)}
				</GridContainer>
				<Table
					columns={[
						{
							Header: t('rules:EARNING_RULES_TABLE.RULE'),
							accessor: 'nameColumn',
							disableSortBy: true,
							style: {
								width: '70%',
							},
						},
						{
							Header: t('rules:EARNING_RULES_TABLE.CURRENCY_AMOUNT'),
							accessor: 'currencyAmount',
							disableSortBy: true,
							style: {
								width: '10%',
							},
						},
						{
							Header: t('common:POINTS'),
							accessor: 'points',
							disableSortBy: true,
							style: {
								width: '10%',
							},
						},
						{
							Header: t('common:ACTIONS'),
							accessor: 'action',
							label: t('VIEW'),
							disableSortBy: true,
							style: {
								width: '10%',
							},
						},
					]}
					loading={loading}
					data={dataRules}
					emptyMessage={t('EMPTY_DATA_TABLE')}
					pagination={{
						...TABLE_PAGINATION_CONFIG,
						onChange: (page, pageSize) => {
							if (pageSize !== pageData.pageSize) {
								AnalyticsService.events.tableItemsPerPageChanged({
									...pageDataEventParams,
									...userInfo,
									items_per_page: `${pageSize}`,
								})
							}
							if (page !== pageData.page) {
								AnalyticsService.events.paginationClicked({
									// 'interactionType' cannot be mapped in the current table
									interaction_type: null,
									page_number: `${page}`,
									screen_name: ScreenName.EarningRules,
									...userInfo,
								})
							}
							setPageData({ page, pageSize })
						},
					}}
				/>
			</div>
		</GridContainer>
	)
}

export default RulesListRedesignView
